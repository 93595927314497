
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { CustomerService } from '@/services/customer-service';
import { Orderline } from '../store/models/OrderlineModel';
import { QuickDialog, AlertModel } from '../store/models/DialogModel';
import { ConversionHelper } from '../helpers/ConversionHelper';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import AddEditShippingAddress from '@/components/AddEditShippingAddress.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: { RollDirectionAlert, AddEditShippingAddress }
})
export default class extends Vue {
  $refs!: {
    AddressChangeForm: HTMLFormElement;
    AddEditShippingAddress: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  @Prop()
  selectedShippingAddress!: any;
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  valid: boolean = true;
  isSaving: boolean = false;
  shippingAddress: any = null;
  addressOptions: any[] = [];
  dialogLayoutData: QuickDialog = new QuickDialog();
  selectedRollDirection: number | null = null;
  defaultCustomerRollDirection!: number;
  shouldShowAlert: boolean = false;
  shouldUpdateOrder: boolean = false;

  /* Methods */
  async showDialog() {
    this.shouldUpdateOrder = false;
    this.getCurrentCustomer();
    if (!this.addressOptions || !this.addressOptions[0]) {
      await this.getShippingAddresses();
    }
    if (this.selectedShippingAddress) {
      this.shippingAddress = this.addressOptions.find(
        a => a.Id === this.selectedShippingAddress.Id
      );
    }
    this.setSelectedRollDirection();
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
    this.shouldShowAlert = false;
  }

  onAddressChange() {
    this.setSelectedRollDirection();
  }
  async setSelectedRollDirection() {
    this.selectedRollDirection = this.shippingAddress.IsCustomerPickup
      ? this.defaultCustomerRollDirection
      : this.shippingAddress.Options.RollDirectionId;
    this.shouldShowAlert = this.selectedRollDirection === 10;
  }

  async onUpdateRollDirectionId() {
    let oldAddress = this.shippingAddress;
    await this.getShippingAddresses();
    let newAddress = this.addressOptions.find((a: any) => a.Id === oldAddress.Id);
    if (newAddress) {
      this.shippingAddress = newAddress;
      this.shippingAddress.Options.RollDirectionId = newAddress.Options.RollDirectionId;
    }
  }
  closeAlert() {
    this.shouldShowAlert = false;
  }
  async addressChangeClick() {
    this.valid = this.$refs.AddressChangeForm.validate();
    if (this.valid) {
      this.isSaving = true;
      this.shippingAddress.ShouldChangeAllAddresses = this.shouldUpdateOrder;
      this.$emit('changeAddress', this.shippingAddress);
      this.isSaving = false;
      this.closeDialog();
    }
  }
  async updateAddress(newAddress: any) {
    await this.getShippingAddresses();
    if (newAddress) {
      this.shippingAddress = this.addressOptions.find(a => a.Id === newAddress.Id);
    }
  }
  openNewAddressDialog() {
    this.$refs.AddEditShippingAddress.openAddressDialog(true);
  }
  /* Loaders */
  async getShippingAddresses() {
    try {
      const response = await CustomerService.GetShippingAddressesFull();
      this.addressOptions = response.data;
    } catch (err) {
      SetSnackBar(
        `There was an error grabbing your shipping address. 
        Please refresh your page, or contact techincal support if error persists`
      );
    }
  }
  async getCurrentCustomer() {
    if (!this.currentCustomer.Name) await this.getCurrentCustomerStore();
    let currentCustomer = this.currentCustomer;
    this.defaultCustomerRollDirection = currentCustomer.Options.RollDirection.Position;
  }
  /* Mounted */
  async mounted() {}
  /* Created */
}
